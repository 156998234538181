
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";

import {
  notificationSuccess,
  notificationError,
} from "@/utils/NotificationUtils";
import { CarListToPostRequest } from "@/services/transform/CarDataTransform";

import {
  BrandList,
  LicenseTypesList,
  ModelsList,
  CarsInsertRequest,
  CarFetchOneRequest,
  StatusCarTypes,
  CarUpdateRequest,
} from "@/services/api/ApiCar";
import {
  IBrandListRequest,
  IStatusCarListRequest,
  ICarsPostRequest,
} from "@/services/models/car";
import IModelListRequest from "@/services/models/car/IModelsListRequest";
import { ILicenseTypes } from "@/services/models";

@Component({
  validations: {
    form: {
      model_id: { required },
      license_type_id: { required },
      license_plate: { required },
      mileage: { required },
      year: { required },
      brand_id: { required },
    },
  },
})
export default class CarFom extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() car_id!: number;

  loading = false;
  brandList: IBrandListRequest[] = [];
  modelsList: IModelListRequest[] = [];
  licenseTypes: ILicenseTypes[] = [];
  statusCarList: IStatusCarListRequest[] = [];
  form: ICarsPostRequest = {
    id: undefined,
    year: "",
    brand_id: undefined,
    // brand: "",
    model_id: undefined,
    // model: "",
    license_plate: "",
    mileage: undefined,
    // status: "",
    car_status_type_id: undefined,
    license_type_id: undefined,
    // license_type: "",
  };

  async requestBrandList(): Promise<void> {
    let brandList = await BrandList();
    this.brandList = brandList;
    // console.log(brandList);
  }
  async requestModelList(id?: number): Promise<void> {
    if (id) {
      let modelsList = await ModelsList(id);
      this.modelsList = modelsList;
    }
  }

  async requestLicenseTypesList(): Promise<void> {
    let licenseTypesList = await LicenseTypesList();
    this.licenseTypes = licenseTypesList;
  }
  async StatusCarTypes(): Promise<void> {
    let statusTypesCarList = await StatusCarTypes();
    this.statusCarList = statusTypesCarList;
    // console.log(statusTypesCarList);
  }
  wordToUpperCase(word: string): void {
    this.form.license_plate = word.toUpperCase();
  }

  close(): void {
    this.$emit("close_modal");
    this.$v.$reset();
    if (!this.car_id) {
      this.reset();
    }
  }

  async edit(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        if (this.form.id) {
          let data = {
            ...this.form,
            // car_status_type_id: this.form.status_id,
          };
          await CarUpdateRequest(this.form.id, data);
          this.close();
          notificationSuccess(
            `${this.$t("cars.messages.success.message.update")}`
          );
          this.$emit("reload-page");
        }
      } catch (error) {
        console.error(error);
        notificationError(`${this.$t("cars.messages.error.message.update")}`);
      }
    }
  }

  async save(): Promise<void> {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        let newMileage = this.form.mileage;
        let data = {
          ...this.form,
          mileage: Number(newMileage),
        };
        await CarsInsertRequest(data);
        this.close();
        notificationSuccess(
          `${this.$t("cars.messages.success.message.create")}`
        );
        this.$emit("reload-page");
      } catch (error) {
        console.error(error);
        notificationError(`${this.$t("cars.messages.error.message.create")}`);
      }
    }
  }

  reset(): void {
    this.form = {
      id: undefined,
      year: "",
      brand_id: undefined,
      // brand: "",
      model_id: undefined,
      // model: "",
      license_plate: "",
      mileage: undefined,
      // status: "",
      // status_id: undefined,
      license_type_id: undefined,
      // license_type: "",
    };
    // this.optionsCity = [];
  }

  @Watch("car_id")
  async User(): Promise<void> {
    if (this.car_id) {
      this.loading = true;
      let car = await CarFetchOneRequest(this.car_id);
      let carFormatedPost = CarListToPostRequest(car);
      this.form = carFormatedPost;
      this.requestModelList(this.form.brand_id);
      // console.log(this.form);
      this.loading = false;
    } else {
      this.reset();
    }
  }

  @Watch("open_modal", { deep: true })
  async openModal(): Promise<void> {
    // console.log(this.brandList);
    if (this.open_modal) {
      this.requestLicenseTypesList();
      this.requestBrandList();
      this.StatusCarTypes();
    } else {
      this.brandList = [];
      this.modelsList = [];
      this.licenseTypes = [];
      this.statusCarList = [];
      // this.optionsState = [];
    }
  }
}
