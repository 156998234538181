import { ICarsPostRequest, ICar } from "../models/car";

export const CarListToPostRequest = (car: ICar): ICarsPostRequest => {
  const formatedCar = {
    id: car.id,
    year: car.year,
    brand_id: car.model.brand_id,
    model_id: car.model.id,
    license_plate: car.license_plate,
    mileage: Number(car.mileage),
    car_status_type_id: car.car_status.car_status_type_id,
    license_type_id: car.license_type_id
  };
  return formatedCar as ICarsPostRequest;
};
