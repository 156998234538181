
import { Component, Vue, Watch } from "vue-property-decorator";
import Carform from "@/views/cars/car-form/CarForm.vue";

import { CarsList } from "@/services/api/ApiCar";
import { ICarsListRequest, ICar } from "@/services/models/car/index";

import { notificationError } from "@/utils/NotificationUtils";

@Component({
  components: {
    Carform
  }
})
export default class Car extends Vue {
  loading = false;
  openModal = false;
  items: ICar[] = [];
  car_id: number | null = null;
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  pages = 0;
  pageOptions: number[] = [10, 25, 50, 100];
  filter = "";
  countPage = 0;
  fields: { key: string; label: string }[] = [
    {
      key: "brand",
      label: `${this.$t("cars.fields.brand")}`
    },
    {
      key: "model",
      label: `${this.$t("cars.fields.model")}`
    },
    {
      key: "license_plate",
      label: `${this.$t("cars.fields.license_plate")}`
    },
    {
      key: "license_type",
      label: `${this.$t("cars.fields.license_type")}`
    },
    {
      key: "status",
      label: `${this.$t("cars.fields.status")}`
    },
    {
      key: "year",
      label: `${this.$t("cars.fields.year")}`
    },
    {
      key: "mileage",
      label: `${this.$t("cars.fields.mileage")}`
    },
    {
      key: "actions",
      label: `${this.$t("cars.fields.actions")}`
    }
  ];

  @Watch("perPage")
  onPerpageChange() {
    this.carList();
  }

  @Watch("currentPage")
  onCurrentPageChange() {
    this.carList();
  }
  
  mounted(): void {
    this.carList();
  }

  async carList(): Promise<void> {
    this.loading = true;
    try {
      let response = await CarsList(this.currentPage, this.perPage);
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  showModal(): void {
    this.openModal = true;
    this.car_id = null;
  }

  closeModal(): void {
    this.openModal = false;
  }

  async showEditModal(id: number): Promise<void> {
    try {
      this.car_id = id;
      this.openModal = true;
    } catch (error) {
      console.error(error);
      notificationError(`${this.$t("cars.messages.error.message.searchUser")}`);
    }
  }

  showPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  onFiltered(filteredItems: ICarsListRequest[]): void {
    if (filteredItems.length > 0) {
      this.countPage = Math.ceil(this.totalRows / this.perPage);
    } else {
      this.countPage = 1;
    }
  }

  reloadPage(): void {
    this.carList();
  }

}
