import {
  ICarsListRequest,
  IModelsListRequest,
  IBrandListRequest,
  IStatusCarListRequest,
  ICarsPostRequest,
  ICar
} from "../models/car";
import ApiBase from "./ApiBase";
import { ILicenseTypes } from "../models";

const USER_PATH = "/dash";
const ADMIN = "/admin";
const CARS = "/cars";
const BRANDS = "/brands";
const MODELS = "/models";
const LICENSE = "/licenseTypes";
const DRIVERS = "/drivers";
const STATUS = "/status";
/**
 * Exibe os Carros
 * @returns { ICarsListRequest } - Lista os carros
 */
export const CarsList = async (
  page: number,
  offset: number
): Promise<ICarsListRequest> => {
  const res = await ApiBase.get(
    `${USER_PATH}${ADMIN}${CARS}?offset=${offset}&page=${page}`
  );
  return res?.data as ICarsListRequest;
};

/**
 * Insere dados do carro
 * @param { ICarsPostRequest } car - Dados para adicionar
 * @returns { ICarsPostRequest } - Carro adicionado
 */
export const CarsInsertRequest = async (
  car: ICarsPostRequest
): Promise<ICarsPostRequest> => {
  const res = await ApiBase.post(`${USER_PATH}${ADMIN}${CARS}`, car);
  return res?.data as ICarsPostRequest;
};

/**
 * Altera dados do carro de acordo com o id informado
 * @param { number } id - Id do carro para edição
 * @param { ICarsPostRequest } user - Dados do carro
 * @returns { ICarsPostRequest } - Dados do carro alterado
 */
export const CarUpdateRequest = async (
  id: number,
  car: ICarsPostRequest
): Promise<ICarsPostRequest> => {
  const res = await ApiBase.put(`${USER_PATH}${ADMIN}${CARS}/${id}`, car);
  return res?.data as ICarsPostRequest;
};

/**
 * Seleciona o usuário de acordo com o id informado
 * @param { number } id - Id do usuário para seleção
 * @returns { ICarsListRequest } - Usuário com o id informado
 */
export const CarFetchOneRequest = async (
  id: number
): Promise<ICar> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${CARS}/${id}`);
  return res?.data as ICar;
};

/**
 * Exibe os Marcas
 * @returns { IBrandListRequest } - Lista os marcas
 */
export const BrandList = async (): Promise<IBrandListRequest[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${CARS}${BRANDS}`);
  // console.log(res.data);
  return res?.data as IBrandListRequest[];
};

/**
 * Exibe os Modelo
 * @returns { IModelsListRequest } - Lista os Modelo
 * @params Id da marca
 */
export const ModelsList = async (
  id: number
): Promise<IModelsListRequest[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${CARS}${MODELS}/${id}`);
  // console.log(res.data);
  return res?.data as IModelsListRequest[];
};

/**
 * Exibe os Tipos de licença
 * @returns { ILicenseTypes } - Lista os Tipos de licença
 */
export const LicenseTypesList = async (): Promise<ILicenseTypes[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${DRIVERS}${LICENSE}`);
  // console.log(res.data);
  return res?.data as ILicenseTypes[];
};

/**
 * Exibe os Tipos de Status
 * @returns { IStatusCarListRequest } - Lista os Tipos de status
 */
export const StatusCarTypes = async (): Promise<
  IStatusCarListRequest[] | []
> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${DRIVERS}${STATUS}`);
  // console.log(res.data);
  return res?.data as IStatusCarListRequest[];
};
