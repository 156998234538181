var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.form.id
      ? ("" + (_vm.$t('cars.modal.form.title.update')))
      : ("" + (_vm.$t('cars.modal.form.title.create'))),"no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":"","footer-class":"d-flex justify-content-between","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cars.modal.form.cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.form.id ? _vm.edit() : _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("cars.modal.form.save"))+" ")])]},proxy:true}]),model:{value:(_vm.open_modal),callback:function ($$v) {_vm.open_modal=$$v},expression:"open_modal"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-form',[_c('b-form-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.brand'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.brandList,"value-field":"id","text-field":"name","state":!_vm.$v.form.brand_id.required && _vm.$v.form.brand_id.$dirty
                      ? false
                      : null},on:{"change":function($event){return _vm.requestModelList(_vm.form.brand_id)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.car_id === undefined ? undefined : _vm.form.brand.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.brandList.length > 0 ? _vm.$t("cars.modal.form.option") : _vm.$t("cars.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.brand_id),callback:function ($$v) {_vm.$set(_vm.form, "brand_id", $$v)},expression:"form.brand_id"}})],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.model'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.modelsList,"value-field":"id","text-field":"name","disabled":!_vm.form.brand_id,"state":!_vm.$v.form.model_id.required && _vm.$v.form.model_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.car_id === undefined ? undefined : _vm.form.model_id}},[_vm._v(" "+_vm._s(_vm.$t("cars.modal.form.option"))+" ")])]},proxy:true}]),model:{value:(_vm.form.model_id),callback:function ($$v) {_vm.$set(_vm.form, "model_id", $$v)},expression:"form.model_id"}})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.year'),"label-class":"pb-0"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],class:!_vm.$v.form.year.required && _vm.$v.form.year.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","placeholder":"2012","trim":""},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.license_type'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.licenseTypes,"value-field":"id","text-field":"name","state":!_vm.$v.form.license_type_id.required &&
                    _vm.$v.form.license_type_id.$dirty
                      ? false
                      : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":_vm.form.car_id === undefined
                          ? undefined
                          : _vm.form.licenseTypes.id,"disabled":true}},[_vm._v(" "+_vm._s(_vm.brandList.length > 0 ? _vm.$t("cars.modal.form.option") : _vm.$t("cars.modal.form.loading"))+" ")])]},proxy:true}]),model:{value:(_vm.form.license_type_id),callback:function ($$v) {_vm.$set(_vm.form, "license_type_id", $$v)},expression:"form.license_type_id"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.mileage'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.mileage.required && _vm.$v.form.mileage.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","type":"number","trim":""},model:{value:(_vm.form.mileage),callback:function ($$v) {_vm.$set(_vm.form, "mileage", $$v)},expression:"form.mileage"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":_vm.car_id ? '6' : '12'}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.license_plate'),"label-class":"pb-0"}},[_c('b-form-input',{class:!_vm.$v.form.license_plate.required &&
                    _vm.$v.form.license_plate.$dirty
                      ? 'is-invalid'
                      : '',attrs:{"size":"sm","trim":""},on:{"keyup":function($event){return _vm.wordToUpperCase(_vm.form.license_plate)}},model:{value:(_vm.form.license_plate),callback:function ($$v) {_vm.$set(_vm.form, "license_plate", $$v)},expression:"form.license_plate"}})],1)],1),(_vm.car_id)?_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('cars.fields.status'),"label-class":"pb-0"}},[_c('b-form-select',{attrs:{"size":"sm","options":_vm.statusCarList,"value-field":"id","text-field":"name","state":!_vm.$v.form.license_type_id.required &&
                    _vm.$v.form.license_type_id.$dirty
                      ? false
                      : null},model:{value:(_vm.form.car_status_type_id),callback:function ($$v) {_vm.$set(_vm.form, "car_status_type_id", $$v)},expression:"form.car_status_type_id"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }